import { transitionZoomLevel } from './apple-maps';
import { convertFilterAndPositionToUrlString } from './listing-query-helper';

import type { ListingParams } from 'contexts/preferences/listing-params/types';

export default function getSearchPageParameters(listingParams: ListingParams) {
  const param = convertFilterAndPositionToUrlString(listingParams, listingParams.filter.latitude, listingParams.filter.longitude, transitionZoomLevel);
  const newUrlParams = new URLSearchParams((param as unknown as string)).toString();
  return `/search?${newUrlParams}`;
}
