import React from 'react';
import AccessibilityIcon from 'components/icon/accessibility-icon';
import styles from './style.module.scss';

export default function AccessibilityWidget() {
  const openWidget = () => document.getElementById('INDmenu-btn')?.click();

  return (
    <div className={styles.component} onClick={openWidget}>
      <AccessibilityIcon />
    </div>
  );
}
