import React, { useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { buildClassName } from 'utils/build-class-name';
import Link from 'components/link';
import { useUserContext } from 'contexts/user';
import { IModalContext, ModalContext } from 'contexts/modal';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import MenuButton from 'components/header/exp/menu-button';
import ThemedIcon from 'components/themed-icon';
import DropdownArrowIcon from 'components/icon/dropdown-arrow-icon';
import dataJSON from './data.json';
import styles from './style.module.scss';
import { headerIds, testIds } from 'constants/test-constants';
import { useFeaturesContext } from 'contexts/features';
import { CountryCodeList } from 'types/countries';
import { useIsMobile } from 'hooks/use-size-class';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';
import Image from 'next/image';
import Button from 'components/control/button';
import UserIcon from 'components/icon/user-icon';
import { PATHS_WITH_FLOATING_HEADERS } from 'constants/headers';

const Header = () => {
  const { userLinks } = dataJSON;
  const { isAuthenticated, user, siteLocation, signOut } = useUserContext();
  const { features } = useFeaturesContext();
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { themeName, theme } = useThemeContext();
  const [menuDropdownIsActive, setMenuDropdownIsActive] = useState(false);
  const [userDropdownIsActive, setUserDropdownIsActive] = useState(false);
  const [countrySwitcherDropdownIsActive, setCountrySwitcherDropdownIsActive] = useState(false);
  const [Dropdown, setDropdown] = useState<any>();
  const [CountrySwitcher, setCountrySwitcher] = useState<any>();
  const [isScrolled, setIsScrolled] = useState(false);
  const isMobile = useIsMobile();
  const isUsSite = siteLocation === CountryCodeList.UNITED_STATES;
  const router = useRouter();
  const isFloating = PATHS_WITH_FLOATING_HEADERS.includes(router.pathname);
  const isAgentSearch = router.pathname === '/agents-search';

  const toggleMenuDropdown = () => {
    if (!Dropdown) {
      setDropdown(dynamic(import('components/dropdown')));
    }
    setMenuDropdownIsActive(prev => !prev);
  };
  const toggleUserDropdown = () => {
    if (!Dropdown) {
      setDropdown(dynamic(import('components/dropdown')));
    }
    setUserDropdownIsActive(prev => !prev);
  };

  const toggleCountrySwitcher = () => {
    if (!CountrySwitcher) {
      setCountrySwitcher(dynamic(import('components/country-switcher')));
    }
    setCountrySwitcherDropdownIsActive(prev => !prev);
  };
  const CountrySwitcherComponent = () => {
    return (
      <div ref={useOutsideClickHandler(()=>setCountrySwitcherDropdownIsActive(false))}>
        <a className={styles.switcher} onClick={toggleCountrySwitcher}>
          <div className={styles['country-flag']}>
            <Image
              src={isUsSite ? '/next/assets/images/us-flag.svg' : '/next/assets/images/ca-flag.svg'}
              alt="country flag"
              width={1.5}
              height={1.25}
            />
            <span>{isUsSite ? CountryCodeList.UNITED_STATES : CountryCodeList.CANADA}<DropdownArrowIcon className={styles['dropdown-arrow-icon']} /></span>
          </div>
        </a>
        {countrySwitcherDropdownIsActive && <CountrySwitcher toggleDropdown={toggleCountrySwitcher} />}
      </div>
    );
  };

  const generateAuthUserLabel = () => {
    if (user) {
      if (user.firstName || user.lastName) {
        return `${(user?.firstName || '').slice(0, 1)}${(user?.lastName || '').slice(0, 1)}`;
      }
      return user?.email?.slice(0, 1);
    }
    return 'Log In';
  };
  const authUserLabel = generateAuthUserLabel();

  const handleScroll = () => setIsScrolled(window.scrollY > 40);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={buildClassName(styles.component, isFloating && styles.home, isAgentSearch && styles['agent-search'], isScrolled && styles.scrolled)} data-testid={headerIds.header}>
      <div className={buildClassName(styles['layout-container'])}>
        <div className={buildClassName(styles['logo-wrapper'])} data-testid={headerIds.zoocasaHeaderLogo}>
          <Link href="/" as="/" className={styles.logo} rel="noreferrer"><ThemedIcon /></Link>
        </div>
        <div className={styles.navigation}>
          <Link href="/search"><Button label="Find Your Home" className={styles.search} /></Link>
          {features.useUsListings && themeName !== ThemeNames.EXP_REALTY_CA && <CountrySwitcherComponent />}
          {isAuthenticated
            ?
            <MenuButton onClick={toggleUserDropdown} closeMenu={() => setUserDropdownIsActive(false)}>
              <span data-testid={headerIds.loggedInUser}>{authUserLabel} {userDropdownIsActive && Dropdown && <Dropdown items={userLinks} signOut={signOut} />}</span>
            </MenuButton>
            :
            <Button 
              label={!isMobile ? authUserLabel : ''} 
              Icon={isMobile && UserIcon} 
              onClick={() => openModal('login-registration')}
              className={buildClassName(styles.auth, isMobile && styles.user)}
            />
          }
          <MenuButton className={styles.menu} testId={testIds.menuButton} onClick={toggleMenuDropdown} closeMenu={() => setMenuDropdownIsActive(false)}>
            <>
              <div className={styles['bar-container']} id="menu">
                <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
                <span className={buildClassName(styles.bar, menuDropdownIsActive && styles.x)} />
              </div> 
              {menuDropdownIsActive && Dropdown && <Dropdown items={theme.menuLinks} />}
            </>
          </MenuButton>
        </div>
      </div>
    </div>
  );
};

export default Header;
