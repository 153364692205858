import { useThemeContext } from 'contexts/theme';
import ExprealtyCaIcon from 'components/icon/exprealty-ca-icon';
import ZoocasaIcon from 'components/icon/zoocasa-icon';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  onClick?: () => void;
}

export default function ThemedIcon({ className, onClick }: Props) {
  const { themeName } = useThemeContext();

  switch (themeName) {
  case ThemeNames.EXP_REALTY_CA:
  case ThemeNames.EXP_REALTY_US:
    return <ExprealtyCaIcon className={className} onClick={onClick} />;
  default:
    return <ZoocasaIcon className={className} onClick={onClick} />;
  }
}
