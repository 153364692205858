import { camelizeKeys, dasherizeKeys } from '@zoocasa/node-kit/objects/transform-keys';
import { formatQueryIntoURLQueryParams } from 'utils/listing-query-helper';
import { camelize } from '@zoocasa/node-kit/strings/camelize';
import { HttpRequestMethodTypes } from 'types';
import endpoint from 'utils/endpoint';
import { Meta } from 'utils/types';

import type { ThemeNames } from 'types/themes';

type CreateSavedSearchResponse = {
  data: Record<string, unknown>[];
  errors: {
    source?: {
      pointer?: string;
    };
    title?: string;
  }[];
};

export type SavedSearchRequestParams = {
  page: {
    size: number;
    number?: number;
  };
  source: ThemeNames;
};

export default class SavedSearch {
  imageUrl: string | null;
  listingsCount: number | null;
  description: string;
  frequency: number;
  createdAt: Date;
  updatedOn: Date;
  id: string;
  formParams: {
    filter: {
      additional: {
          condoOrTownhouse: {
            locker: 'any' | 'yes' | 'no';
            maintenanceFee: number | null;
          } | null;
          house: {
            basementApartment: boolean;
            duplex: boolean;
            'fourplex+': boolean;
            singleFamily: boolean;
            triplex: boolean;
          } | null;
      };
      areaName: string | null;
      bathrooms: string;
      bedrooms: string;
      boundary: string | null;
      fireplace: boolean;
      garage: boolean;
      homeType: {
          condo: boolean;
          houseAttached: boolean;
          houseDetached: boolean;
          houseSemidetached: boolean;
          townhouse: boolean;
      };
      latitude: number;
      listedSince: string | null;
      listedTo: string | null;
      longitude: number;
      openHouse: boolean;
      parkingSpaces: string;
      pool: boolean;
      priceMax: number | null;
      priceMin: number | null;
      rental: boolean;
      slug: string | null;
      sqftMax: number | null;
      sqftMin: number | null;
      status: 'available' | 'not-available' | 'not-available-sold' | 'not-available-other';
      waterfront: boolean;
      zoom: number;
      providerId: string | null;
    };
  };

  constructor(savedSearch: Record<string, unknown>) {
    const camelizedSavedSearch = camelizeKeys(savedSearch);
    const attributes = camelizedSavedSearch.attributes as Record<string, unknown>;
    const relationships = camelizedSavedSearch.relationships as Record<
      string,
      unknown
    >;
    const formattedSavedSearch = {
      ...attributes,
      ...relationships,
      id: camelizedSavedSearch.id,
      createdAt: new Date(attributes.createdAt as string),
      updatedOn: new Date(attributes.updatedOn as string),
    } as SavedSearch;
    Object.assign(this, formattedSavedSearch);
  }

  get path() {
    return `/search?${formatQueryIntoURLQueryParams(this.formParams.filter)}`;
  }
}

export async function getSavedSearches(params: SavedSearchRequestParams) {
  try {
    const { data, meta } = await endpoint<{ data: Record<string, unknown>[]; meta: Meta }>('/services/api/v3/saved-searches', HttpRequestMethodTypes.GET, params);
    const savedSearches = data.filter((p: any) => typeof p?.attributes?.['form-params']?.filter?.rental === 'boolean').map(d => new SavedSearch(d));
    return { savedSearches, meta };
  } catch {
    return { savedSearches: [] as SavedSearch[], meta: { totalPages: 0, totalCount: 0, pageNumber: 0, pageSize: 0 }};
  }
}



export async function createSavedSearch(properties: Record<string, unknown>) {
  const response = await endpoint<CreateSavedSearchResponse>('/services/api/v3/saved-searches', HttpRequestMethodTypes.POST, {
    data: {
      type: 'saved-searches',
      attributes: dasherizeKeys(properties),
    },
  });
  if ('errors' in response) {
    throw {
      errors: response.errors.map(({ source, title }) => {
        return {
          attribute: camelize((source?.pointer || '').replace('/data/attributes/', '')),
          message: title,
        };
      }),
    };
  }
  return response;
}

export async function destroyRecord(id: string) {
  await endpoint(`/services/api/v3/saved-searches/${id}`, 'DELETE');
}

export async function updateRecord(id: string, attributes: SavedSearch) {
  await endpoint(`/services/api/v3/saved-searches/${id}`, 'PATCH', { data: { attributes }});
}